@font-face {
  font-family: 'HKGrotesk';
  font-weight: normal;
  src: url('/static/wilderlist/fonts/HKGrotesk-Regular.woff');
}

@font-face {
  font-family: 'HKGrotesk';
  font-weight: bold;
  src: url('/static/wilderlist/fonts/HKGrotesk-Bold.woff');
}

:root {
  font-size: 16px;
}

body, html, input, button {
  font-family: 'HKGrotesk';
}

.main-container {
    min-height: 90vh;
}
